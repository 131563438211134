/* API is on the same URL, so we'll just use host-relative paths */
export const API_URL = process.env.NODE_ENV === 'production' ? '' : '';

export interface LoginData {
    actionId?: string;
    sso?: string;
    sig?: string;
    email: string;
    password: string;
    ref?: string;
    SAMLRequest?: string;
}

export interface User {
    firstName: string;
    lastName: string;
    company: string;
    email: string;
    receiveNewsletter: boolean;
    isAdmin?: boolean;
}

export interface APIToken {
    expireAt: string;
    description: string;
    token: string;
    fullToken?: string;
    createdAt: string;
    _id: string;
}

export interface APICertificate {
    id: string;
    firstName: string;
    lastName: string;
    courseName: string;
    createdAt: string;
}

export const getApiTokens = async (): Promise<[tokens?: APIToken[], error?: string]> => {
    try {
        const response = await fetch(API_URL + '/apitokens', {
            method: 'GET',
            credentials: 'include',
        });
        if (response.status === 200) {
            const tokens = await response.json();
            return [tokens, undefined];
        }
        return [[], 'Failed to load tokens'];
    } catch (err) {
        return [undefined, (err as Error).message];
    }
};

export const createApiToken = async (
    description: string,
    expireAt: number
): Promise<[token?: APIToken, error?: string]> => {
    try {
        const response = await fetch(API_URL + '/apitokens', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                description,
                expireAt,
            }),
        });
        const token = await response.json();
        const dataStr =
            'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(token));
        const dlAnchorElem = document.createElement('a');
        dlAnchorElem.setAttribute('href', dataStr);
        dlAnchorElem.setAttribute('download', token._id + '.json');
        dlAnchorElem.click();

        return [token, undefined];
    } catch (err) {
        console.error('could not create a new token', err);
        return [undefined, (err as Error).message];
    }
};
export const deleteApiToken = async (id: string) => {
    try {
        const response = await fetch(API_URL + '/apitokens/' + id, {
            method: 'DELETE',
            credentials: 'include',
        });
        return [response.status === 200, undefined];
    } catch (err) {
        console.error('could not delete a token', err);
        return [undefined, (err as Error).message];
    }
};

export const login = async ({
    email,
    password,
    sso,
    sig,
    ref,
    actionId,
    SAMLRequest,
}: LoginData) => {
    const urlEncodedEmail = encodeURIComponent(email);
    const urlEncodedPassword = encodeURIComponent(password);
    const urlEncodedSAMLRequest = encodeURIComponent(SAMLRequest || '');
    try {
        const response = await fetch(API_URL + '/auth/login', {
            body: `email=${urlEncodedEmail}&password=${urlEncodedPassword}&sso=${sso}&ref=${ref}&sig=${sig}&actionId=${actionId}&SAMLRequest=${urlEncodedSAMLRequest}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-requester': 'web-app',
            },
            method: 'post',
            credentials: 'include',
            mode: 'no-cors',
        });
        if (response.status === 200) {
            return true;
        } else {
            throw new Error(
                'Failed to login, status: ' +
                    response.status.toString() +
                    '. If you have recently created an account, please verify your email first.'
            );
        }
    } catch (err) {
        console.error('could not login', err);
        throw new Error('Technical error during login');
    }
};

export const verify = async ({token}: {token: string}) => {
    const response = await fetch(API_URL + '/auth/verify', {
        body: JSON.stringify({
            token,
        }),
        headers: {
            'Content-Type': 'application/json',
            'x-requester': 'web-app',
        },
        method: 'post',
        credentials: 'include',
    });

    if (response.status === 200) {
        return true;
    } else {
        throw new Error('Failed to verify token, status: ' + response.status.toString());
    }
};

export const resetPassword = async ({email}: {email: string}) => {
    const response = await fetch(API_URL + '/auth/requestReset', {
        body: `email=${encodeURIComponent(email)}`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-requester': 'web-app',
        },
        method: 'post',
        credentials: 'include',
    });

    if (response.status === 302) {
        return true;
    } else {
        throw new Error('Failed to verify token, status: ' + response.status.toString());
    }
};

export const logOut = async () => {
    try {
        await fetch(API_URL + '/auth/logout', {
            method: 'GET',
            credentials: 'include',
            redirect: 'manual',
        });
    } catch (err) {
        console.log('failed to logout', err);
    }
    return null;
};

export const createUser = async (
    firstName: string,
    lastName: string,
    company: string,
    receiveNewsletter: boolean,
    email: string,
    password: string,
    cfToken: string
): Promise<{message?: string}> => {
    try {
        const response = await fetch(API_URL + '/auth/signup', {
            body: `firstName=${firstName}&lastName=${lastName}&company=${company}&receiveNewsletter=${receiveNewsletter}&email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}&cf-turnstile-response=${cfToken}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-requester': 'web-app',
            },
            method: 'post',
            credentials: 'include',
        });
        if (response.status === 200) {
            return {message: 'Please validate your email to enable your user account.'};
        } else {
            return {message: 'Please validate your email to enable your user account.'};
        }
    } catch (error) {
        console.log('error', error);
        return {message: 'Failed to create user.'};
    }
};

export const getUserData = async (): Promise<[user?: User, error?: string]> => {
    try {
        const response = await fetch(API_URL + '/user/me', {
            method: 'GET',
            credentials: 'include',
        });
        if (response.status < 300) {
            const userData = await response.json();
            return [userData.user];
        } else {
            return [
                undefined,
                'Failed to load user data, please validate your email or check your credentials.',
            ];
        }
    } catch (err) {
        console.log('faied to load user data', err);

        return [
            undefined,
            'Failed to load user data, please validate your email or check your credentials.',
        ];
    }
};

export const updateUserData = async (
    firstName: string,
    lastName: string,
    company: string,
    receiveNewsletter: boolean
) => {
    try {
        const response = await fetch(API_URL + '/user/', {
            body: `firstName=${firstName}&lastName=${lastName}&company=${company}&receiveNewsletter=${receiveNewsletter}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-requester': 'web-app',
            },
            method: 'post',
            credentials: 'include',
        });

        return [await response.json(), undefined];
    } catch (error) {
        console.log('update user error', error);
        return [undefined, (error as Error).message];
    }
};

export const requestPasswordReset = async (
    email: string,
    cfToken: string
): Promise<void> => {
    try {
        // TODO:
        const response = await fetch(API_URL + '/auth/requestReset', {
            body: `email=${email}&cfToken=${cfToken}`,
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-requester': 'web-app',
            },
        });
        if (response.status === 302) {
            return;
        } else {
            return;
        }
    } catch (err) {
        return;
    }
};

export const passwordReset = async ({
    email,
    cfToken,
    token,
    password,
}: {
    email: string;
    cfToken: string;
    token: string;
    password: string;
}): Promise<void> => {
    try {
        // TODO:
        const response = await fetch(API_URL + '/auth/reset', {
            body: `email=${email}&cfToken=${cfToken}&token=${token}&password=${password}`,
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-requester': 'web-app',
            },
        });
        if (response.status === 302) {
            return;
        } else {
            return;
        }
    } catch (err) {
        return;
    }
};

export const getCertificates = async (): Promise<{
    certificates?: APICertificate[];
    error?: string;
}> => {
    try {
        const response = await fetch(API_URL + '/certificate', {
            method: 'GET',
            credentials: 'include',
        });
        if (response.status < 300) {
            return await response.json();
        }
        return {
            error: 'Failed to load certificates, status: ' + response.status.toString(),
        };
    } catch (err) {
        return {error: (err as Error).message};
    }
};

export const createCertificate = async (cert: {
    firstName: string;
    lastName: string;
    courseName: string;
}): Promise<{certificate?: APICertificate; error?: string}> => {
    try {
        const response = await fetch(API_URL + '/certificate', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(cert),
        });
        const certificate = await response.json();

        return {certificate};
    } catch (err) {
        return {error: (err as Error).message};
    }
};

export const validateCertificate = async (
    certificate: string
): Promise<{certificate?: APICertificate; error?: string; status?: number}> => {
    try {
        const res = await fetch(API_URL + '/certificate/validate', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({certificate}),
        });
        const json = await res.json();
        if (res.status < 300) return {certificate: json.certificate, status: res.status};
        return {error: json.message, status: res.status};
    } catch (err) {
        return {error: (err as Error).message};
    }
};
